import React, { useMemo, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button } from '@spider:src/components';
import ImportDialog from '@spider:src/components/ImportDialog/ImportDialog';
import { ImportLogsHistory } from '@spider:src/components/importLogsHistory';
import { ImportLogsTypes } from '@spider:src/enums/importLogsTypes';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { ParticipantsList } from '@spider:src/components/ParticipantsList';
import { PointsAdminUrl } from '@src/Spider/enums/pointsAdminUrl';
import { useRouteMatch } from 'react-router';
import { ParticipantsAdminUrl } from '@src/Spider/enums/participantsAdminUrl';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
  pointsHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
  },
};

const Participants = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { contract } = useParams();

  const tabPaths = Object.values(ParticipantsAdminUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.participants.tabs.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentTab = pathSegments.pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${match.url}/${tabPaths[Number(newValue)]}`);
  };

  const [openImport, setOpenImport] = useState(false);

  const importPoints = () => {
    setOpenImport(true);
  };

  const redirect = options => {
    if (
      options.type === ImportLogsTypes.POINTS &&
      history.location.pathname.includes('users')
    ) {
      history.push(`/nodes/${contract}/points/${PointsAdminUrl.HISTORY}`);
    }
  };

  if (!currentTab || currentTab === 'users') {
    return <Redirect to={`${match.url}/${ParticipantsAdminUrl.LIST}`} />;
  }

  return (
    <div className={props.classes.pointsBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.participants.title' })}
        </Typography>
        <Button color={'primary'} variant={'outlined'} onClick={importPoints}>
          {intl.formatMessage({ id: 'spider.participants.import' })}
        </Button>
      </TitleContainer>

      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs onChange={handleChange} value={value}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.index.toString()}
                  disableFocusRipple
                />
              ))}
            </Tabs>
          </Box>
          <Switch>
            <Route
              path={`${match.path}/${ParticipantsAdminUrl.LIST}`}
              render={() => (
                <TabPanel value={tabs[0].index.toString()}>
                  <ParticipantsList />
                </TabPanel>
              )}
            />
            <Route
              path={`${match.path}/${ParticipantsAdminUrl.HISTORY}`}
              render={() => (
                <TabPanel value={tabs[1].index.toString()}>
                  <ImportLogsHistory
                    fixedCacheKey={'participants'}
                    importLogType={ImportLogsTypes.USERS}
                    emptyLabelParams={intl.formatMessage({
                      id: 'spider.importLog.dataTable.no_data_participants',
                    })}
                  />
                </TabPanel>
              )}
            />
            <Route
              path='*'
              render={() => (
                <Redirect to={`${match.url}/${ParticipantsAdminUrl.LIST}`} />
              )}
            />
          </Switch>
        </TabContext>
      </Box>
      <ImportDialog
        open={openImport}
        setOpen={setOpenImport}
        onFileCreated={redirect}
        defaultTab={'2'}
        fixedCacheKey={'participant'}
      />
    </div>
  );
};

export default withStyles(styles)(Participants);
