import React, { useMemo } from 'react';
import { Box, Tabs, Typography } from '@material-ui/core';
import { Tab } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { AdministrationUrl } from '../../enums/administrationUrl';
import { TitleContainer } from '../../components/TitleContainer';
import { RegulationsTab } from '@src/Spider/scenes/Admin/components/RegulationsTab';
import { PersonalizationTab } from '@src/Spider/scenes/Admin/components/PersonalizationTab';
import { ParametersTabs } from '@src/Spider/scenes/Admin/components/ParametersTab';
import { useRouteMatch } from 'react-router';

const styles = {
  adminBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    paddingRight: '0.5rem',
  },
  adminHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
  },
};

const Admin = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const tabPaths = Object.values(AdministrationUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.hierarchy_node.platform_customization.adminTabLabel.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentTab = pathSegments.pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${match.url}/${tabPaths[Number(newValue)]}`);
  };

  if (!tabPaths.includes(currentTab))
    return <Redirect to={`${match.url}/${AdministrationUrl.REGULATIONS}`} />;

  return (
    <div className={props.classes.adminBody}>
      <TitleContainer>
        <div className={props.classes.adminHeader}>
          <Typography
            variant={'h1'}
            component={'h1'}
            className={'underline-left'}
          >
            {intl.formatMessage({
              id: 'spider.hierarchy_node.platform_customization.title',
            })}
          </Typography>
        </div>
      </TitleContainer>
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs onChange={handleChange} value={value}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.index.toString()}
                  disableFocusRipple
                />
              ))}
            </Tabs>
          </Box>
          <Switch>
            <Route
              path={`${match.path}/${AdministrationUrl.REGULATIONS}`}
              render={() => (
                <TabPanel value={tabs[0].index.toString()}>
                  <RegulationsTab />
                </TabPanel>
              )}
            />
            <Route
              path={`${match.path}/${AdministrationUrl.PERSONALIZATION}`}
              render={() => (
                <TabPanel value={tabs[1].index.toString()}>
                  <PersonalizationTab />
                </TabPanel>
              )}
            />
            <Route
              path={`${match.path}/${AdministrationUrl.PARAMETERS}`}
              render={() => (
                <TabPanel value={tabs[2].index.toString()}>
                  <ParametersTabs />
                </TabPanel>
              )}
            />
            <Route
              path='*'
              render={() => (
                <Redirect
                  to={`${match.url}/${AdministrationUrl.REGULATIONS}`}
                />
              )}
            />
          </Switch>
        </TabContext>
      </Box>
    </div>
  );
};

export default withStyles(styles)(Admin);
