import React, { useMemo, useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Button } from '@spider:src/components';
import { ImportDialog } from '@spider:src/components/ImportDialog';
import { ImportLogsTypes } from '@spider:src/enums/importLogsTypes';
import { ImportLogsHistory } from '@spider:src/components/importLogsHistory';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { PointsList } from '@spider:src/components/PointsList/PointsList';
import { useRouteMatch } from 'react-router';
import { PointsAdminUrl } from '@src/Spider/enums/pointsAdminUrl';
import { ParticipantsAdminUrl } from '@src/Spider/enums/participantsAdminUrl';
import { OwnerEnum } from '@src/Spider/enums/ownerType';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
};

const AdminPoints = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  const { contract } = useParams();

  const tabPaths = Object.values(PointsAdminUrl);
  const tabs = useMemo(
    () =>
      tabPaths.map((el, i) => ({
        label: intl.formatMessage({
          id: `spider.points.tabs.${el}`,
        }),
        index: i,
        url: el,
      })),
    [intl, tabPaths],
  );

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentTab = pathSegments.pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${match.url}/${tabPaths[Number(newValue)]}`);
  };

  const [openImport, setOpenImport] = useState(false);

  const importPoints = () => {
    setOpenImport(true);
  };

  const redirect = options => {
    if (
      options.type === ImportLogsTypes.USERS &&
      history.location.pathname.includes('points')
    ) {
      history.push(`/nodes/${contract}/users/${ParticipantsAdminUrl.HISTORY}`);
    }
  };

  if (!tabPaths.includes(currentTab))
    return <Redirect to={`${match.url}/${PointsAdminUrl.LIST}`} />;

  return (
    <div className={props.classes.pointsBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.points.title' })}
        </Typography>
        <Button color={'primary'} variant={'outlined'} onClick={importPoints}>
          {intl.formatMessage({ id: 'spider.points.import' })}
        </Button>
      </TitleContainer>
      <Box
        style={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs onChange={handleChange} value={value}>
              {tabs.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.label}
                  value={tab.index.toString()}
                  disableFocusRipple
                />
              ))}
            </Tabs>
          </Box>
          <Switch>
            <Route
              path={`${match.path}/${PointsAdminUrl.LIST}`}
              render={() => (
                <TabPanel value={tabs[0].index.toString()}>
                  <PointsList
                    queryParams={{
                      id: contract,
                      owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
                    }}
                  />
                </TabPanel>
              )}
            />
            <Route
              path={`${match.path}/${PointsAdminUrl.HISTORY}`}
              render={() => (
                <TabPanel value={tabs[1].index.toString()}>
                  <ImportLogsHistory
                    fixedCacheKey={'points'}
                    importLogType={ImportLogsTypes.POINTS}
                    emptyLabelParams={intl.formatMessage({
                      id: 'spider.importLog.dataTable.no_data_points',
                    })}
                  />
                </TabPanel>
              )}
            />
            <Route
              path='*'
              render={() => (
                <Redirect to={`${match.url}/${PointsAdminUrl.LIST}`} />
              )}
            />
          </Switch>
        </TabContext>
      </Box>
      <ImportDialog
        open={openImport}
        setOpen={setOpenImport}
        onFileCreated={redirect}
        defaultTab={'1'}
        fixedCacheKey={'points'}
      />
    </div>
  );
};

export default withStyles(styles)(AdminPoints);
